import React, { useState } from "react"
import { Container, Button, Row, Form, Col } from "react-bootstrap"
import Layout from "../components/layout/Index"
import Title from "../components/commons/page/title/Index"
import { graphql, Link } from "gatsby"
import Card from "../components/commons/card/berita/index"
// import Pagination from "../components/commons/pagination/index"
import VerticalAds from "../components/commons/ads/vertical-ads/Index"
import { withTrans } from "../i18n/withTrans"
import { inRangeDate } from "../utils/date"
import { makeStyles } from "@material-ui/core/styles"
import Pagination from "@material-ui/lab/Pagination"

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}))

const Index = ({ data, t, i18n }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTextTemp, setSearchTextTemp] = useState("")
  const [searchText, setSearchText] = useState("")
  const lengthData = data?.allWpBerita?.totalCount
  const classes = useStyles()

  const language = i18n.language
  const dataFilter = data?.allWpBerita?.nodes?.filter(o =>
    language === "id" ? o?.language?.code !== "EN" : o?.language?.code === "EN"
  )

  const dataIklan = data?.allWpIklanGks?.nodes?.filter(o =>
    language === "id" ? o?.language?.code !== "EN" : o?.language?.code === "EN"
  )
  const dataIklanBronzeAdSpace = dataIklan?.filter(
    o => o.iklan.slotIklan === "Bronze Ad Space (280 x 360 px)"
  )

  const pageSize = 7
  // const pagesCount = Math.ceil(lengthData / pageSize)
  const pagesCount =
    dataFilter.filter(item =>
      item.title.toLowerCase().includes(searchText.toLowerCase())
    ).length / pageSize
  const roundPagesCount = Math.ceil(pagesCount)
  const handlePageClick = (e, i) => {
    setCurrentPage(i)
  }

  const handlePreviousClick = (e, currentPage) => {
    setCurrentPage(currentPage - 1)
  }

  const handleNextClick = (e, currentPage) => {
    setCurrentPage(currentPage + 1)
  }

  const onSearch = e => {
    e.preventDefault()
    setSearchText(searchTextTemp)
  }

  return (
    <Layout>
      <Container className="page-container article-container">
        <Row className="justify-content-between align-items-center mb-4">
          <Col md={6} className="page-title m-0">
            <span>{t("header.news")}</span>
          </Col>
          <Col md={6} xs={12}>
            <Row className="justify-content-between">
              <Col md={12} className="d-flex justify-content-end">
                <Form.Control
                  onChange={e => setSearchTextTemp(e.target.value)}
                  placeholder={t("article.enterKeyword")}
                />
                <Button type="submit" onClick={e => onSearch(e)}>
                  {/* {t("search")} */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <Title label={t("header.news")} />
        <Form className="mini-news-container ">
          <Form.Row>
            <Form.Control
              onChange={e => setSearchTextTemp(e.target.value)}
              placeholder={t("article.enterKeyword")}
            />
            <Button type="submit" onClick={e => onSearch(e)}>
              {t("search")}
            </Button>
          </Form.Row>
        </Form> */}
        {dataFilter?.length > 0 && (
          <Row className="mb-4">
            <Col md={6} className="mini-news-container">
              <img
                src={
                  dataFilter[0]?.news?.thumbnail?.sourceUrl
                    ? dataFilter[0].news.thumbnail.sourceUrl
                    : `../../../../images/Img_default.png`
                }
                alt=""
                style={{
                  objectFit: "cover",
                  display: "block",
                  width: "100%",
                  height: 300,
                  borderRadius: "20px",
                }}
              />
            </Col>
            <Col md={6}>
              <div className="d-flex flex-column h-100">
                <div className="d-flex flex-column h-100 highlight-news">
                  <h2>
                    {/* {data.allWpBerita.nodes[0].title.length > 70
                      ? `${data.allWpBerita.nodes[0].title.substring(
                          0,
                          70
                        )} ...`
                      : data.allWpBerita.nodes[0].title} */}
                    {data.allWpBerita.nodes[0].title}
                  </h2>
                  <br />
                  <span style={{ color: "#00a650", fontWeight: "bold" }}>
                    {data.allWpBerita.nodes[0].date}
                  </span>
                  <div
                    className="highlight-news-content"
                    dangerouslySetInnerHTML={{
                      __html:
                        // dataFilter[0].content?.length > 200
                        //   ? `${dataFilter[0]?.content?.substring(0, 200)} ...`
                        //   : dataFilter[0]?.content,
                        dataFilter[0]?.content,
                    }}
                  />
                </div>
                <div>
                  <Link
                    key={data.allWpBerita.nodes[0].id}
                    href={`berita/${data.allWpBerita.nodes[0].slug}`}
                  >
                    <Button
                      className="button-highlight-news"
                      style={{ borderRadius: 5 }}
                    >
                      {t("viewMore")}
                    </Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        )}
        {dataFilter.length > 1 ? (
          <Row>
            <Col md={12} className="">
              <Row style={{ marginBottom: 20 }}>
                <Col xl={3} md={4} sm={6} xs={12} style={{ padding: 10 }}>
                  {dataIklanBronzeAdSpace?.length > 0 &&
                  inRangeDate(
                    dataIklanBronzeAdSpace[0].iklan.tanggalJangkaIklanAwal,
                    dataIklanBronzeAdSpace[0].iklan.tanggalJangkaIklanAkhir
                  ) &&
                  dataIklanBronzeAdSpace[0].iklan
                    .uploadGambarIklanBronzeAdSpace ? (
                    <VerticalAds
                      width={280}
                      height={360}
                      title="B. BRONZE AD SPACE"
                      img_url={
                        dataIklanBronzeAdSpace[0].iklan
                          .uploadGambarIklanBronzeAdSpace.sourceUrl
                      }
                    />
                  ) : (
                    <VerticalAds
                      width={280}
                      height={380}
                      title="B. BRONZE AD SPACE"
                    />
                  )}
                </Col>
                {/* <Col md={12} style={{ padding: 0 }}>
              <Row style={{ marginBottom: 20 }}>
                <Col md={3} style={{ padding: 10 }}>
                  <VerticalAds
                    width={280}
                    height={380}
                    title="B. BRONZE AD SPACE"
                  />
                </Col> */}
                {dataFilter && dataFilter.length > 0 && roundPagesCount > 0 ? (
                  dataFilter
                    .filter(item =>
                      item.title
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                    )
                    .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                    .map((item, i) => (
                      <Col
                        xl={3}
                        md={4}
                        sm={6}
                        xs={12}
                        style={{ padding: 0 }}
                        key={i}
                        className="mini-news-container"
                      >
                        <Card
                          date={item.date}
                          title={item.title}
                          image={
                            item.news?.thumbnail?.sourceUrl
                              ? item.news?.thumbnail?.sourceUrl
                              : null
                          }
                          key={item.id}
                          slug={`/berita/${item.slug}`}
                        />
                      </Col>
                    ))
                ) : (
                  <div className="d-flex justify-content-center">
                    {t("noData")}
                  </div>
                )}
              </Row>
              {dataFilter && dataFilter.length > 0 && roundPagesCount > 0 && (
                // <div className="d-flex justify-content-center mt-4">
                //   <Pagination
                //     pagesCount={roundPagesCount}
                //     currentPage={currentPage}
                //     handlePageClick={handlePageClick}
                //     handlePreviousClick={handlePreviousClick}
                //     handleNextClick={handleNextClick}
                //   />
                // </div>
                <div
                  className={`${classes.root} d-flex justify-content-center mt-4`}
                >
                  <Pagination
                    count={roundPagesCount}
                    page={currentPage}
                    onChange={handlePageClick}
                    shape="rounded"
                    color="primary"
                  />
                </div>
              )}
              {/* <div className="d-flex justify-content-center">
                <Pagination
                  pagesCount={pagesCount}
                  currentPage={currentPage}
                  handlePageClick={handlePageClick}
                  handlePreviousClick={handlePreviousClick}
                  handleNextClick={handleNextClick}
                />
              </div> */}
            </Col>
          </Row>
        ) : (
          <div>{t("noData")}</div>
        )}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    allWpBerita(
      sort: { fields: date, order: DESC }
      filter: { status: { eq: "publish" } }
    ) {
      nodes {
        id
        news {
          thumbnail {
            sourceUrl
          }
        }
        language {
          code
        }
        date(formatString: "MMMM DD, YYYY")
        id
        slug
        uri
        title
        content
        link
      }
      totalCount
    }

    allWpIklanGks(sort: { fields: date, order: DESC }) {
      nodes {
        title
        iklan {
          slotIklan
          deskripsiIklan
          letakIklan {
            bronzeAdSpace
            goldA
            premium
            silverAdSpace
            goldB
          }
          uploadGambarIklanBronzeAdSpace {
            sourceUrl
          }
          tanggalJangkaIklanAkhir
          tanggalJangkaIklanAwal
        }
        date
        language {
          code
        }
      }
    }
  }
`

export default withTrans(Index)
